import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Background from "../../Common/Background";
import Content from "./Content";
import Title from "../Title";
import { ciscoAuthFeitian, getToken } from "../../Oauth/Oauth";

const styles = {
  root: {
    textAlign: "center",
    paddingTop: 50
  }
};

const FeitianKey = props => {
  const { classes } = props;
  const [auth, setAuth] = useState(false);
  const [cec, setCec] = useState("");
  
  const env = localStorage.getItem('environment');
  let authUrl = "https://cloudsso-test.cisco.com/idp/userinfo.openid";
  if(env==='prod'){
    authUrl = "https://cloudsso.cisco.com/idp/userinfo.openid";
  }
  
  const getCec = () => {
    const config = {
      headers: { Authorization: 'Bearer '+getToken() }
    };
    return axios
      //.get("https://cloudsso.cisco.com/idp/userinfo.openid", config)  
      //.get("https://cloudsso-test.cisco.com/idp/userinfo.openid", config)
      .get(authUrl, config)
      .then(response => {
        setCec(response.data.sub);
      })
      .catch(error => {
        if (error.response.statusText === "Unauthorized") {
          console.log("Refresh Trigerred");
          refreshToken();
        }
      });
  };

  const redirect = () => {
    if (getToken() === null) {
      window.location = ciscoAuthFeitian.token.getUri();
    } else {
      setAuth(true);
      getCec();
    }
  };

  const refreshToken = () => {
    window.location = ciscoAuthFeitian.token.getUri();
  };

  useEffect(() => {
    redirect();
  }, []);

  return (
    <div>
      <Background />
      <div>
        <div className={classes.root}>
          <Title message="Register a new Feitian Key" />
        </div>
        <Content cec={cec} />
        <br></br>
      </div>
    </div>
  );
};

export default withStyles(styles)(FeitianKey);