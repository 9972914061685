import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import duoLogo from "../../assets/duoLogo-web-white.png";
import ciscoLogo from "../../assets/ciscoLogo.png";

const styles = {
  duoImage: {
    width: "auto",
    height: 30,
    verticalAlign: "middle",
    paddingLeft: 10,
    paddingRight: 10
  },
  ciscoImage: {
    width: "auto",
    height: 50,
    color: "white",
    paddingLeft: 10,
    paddingRight: 10,
    verticalAlign: "middle",
    borderRight: "0.1em solid black",
    borderRightColor: "#b2b2b2"
  },
  titleWrapper: {
    paddingBottom: 45,
    paddingTop: 50,
    textAlign: "center"
  }
};

const Title = props => {
  const { classes } = props;
  return (
    <div className={classes.titleWrapper}>
      <Typography variant="h4" color="secondary">
        Welcome to
        <img className={classes.ciscoImage} src={ciscoLogo} alt="cisco logo" />
        <img className={classes.duoImage} src={duoLogo} alt="duo logo" />
        Dashboard
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Title);
