import React from "react";
import FocusPasswordLess from "./FocusPasswordLess";
import Background from "../Common/Background";

const PasswordLess = props => {
  return (
    <div>
      <div>
        <FocusPasswordLess />
      </div>
      <Background />
    </div>
  );
};

export default PasswordLess;
