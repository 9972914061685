import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper, Link } from "@material-ui/core";

const styles = {
  media: {
    width: "auto",
    height: 100,
    objectFit: "contain"
  },
  textBox: {
    minHeight: 75,
    maxHeight: 75
  },
  imageWrapper: {
    height: "100%",
    width: "100%"
  },
  paperWrapper: {
    width: 265,
    maxWidth: 265,
    transition: "transform 0.25s",
    "&:hover": {
      transform: "scale(1.05)"
    },
    // position: "relative",
    display: "inline-block"
    // borderBottom: "1px dotted black"
  },
  link: {
    "&:hover": {
      textDecoration: "none",
      color: "green"
    }
  },

  description: {
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#002F4D"
  },

  tooltiptext: {
    boxSizing: "border-box",
    width: "120px",
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "6px",
    padding: "5px 0",
    position: "relative",
    zIndex: "10",
    bottom: "125%",
    left: "50%",
    marginLeft: "-60px",
    opacity: "0",
    transition: "opacity 0.3s"
  }
};
const PaperBox = props => {
  const { classes } = props;
  const [tip, setTip] = React.useState(false);

  const clickin = () => {
    setTip(true);
  };

  const clickout = () => {
    setTip(false);
  };

  return (
    <div
      className={classes.paperWrapper}
      onMouseOver={clickin}
      onFocus={clickin}
      onMouseOut={clickout}
      onBlur={clickout}
    >
      <Link
        href={props.link}
        target="_blank"
        rel="noopener"
        className={classes.link}
      >
        <Paper elevation={1}>
          <img
            src={props.image}
            width={props.width || 65}
            height={props.height || 85}
            alt="aaa"
          />
          <Typography
            variant="h6"
            component="h5"
            gutterBottom
            className={classes.description}
          >
            {props.title}
          </Typography>
        </Paper>
      </Link>
      <span
        // className={tip ? "classes.tooltiptext" : ""}
        style={{
          display: `${tip ? "block" : "none"}`,
          color: "black",
          fontSize: "14px",
          marginTop: "-22%",
          marginLeft: "60%",
          backgroundColor: "white",
          width: "60%",
          borderRadius: "2px",
          border: "1px solid black",
          opacity: "100"
        }}
      >
        {props.tooltip}
      </span>
    </div>
  );
};

export default withStyles(styles)(PaperBox);
