import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  titleWrapper: {
    paddingBottom: 25
  }
};

const Title = props => {
  const { classes } = props;
  return (
    <div className={classes.titleWrapper}>
      <Typography variant="h4" color="secondary">
        {props.message}
      </Typography>
    </div>
  );
};


export default withStyles(styles)(Title);
