import React from "react";
import Focus from "./Focus";
import Background from "../Common/Background";

const Edit = props => {
  return (
    <div>
      <div>
        <Focus />
      </div>
      <Background />
    </div>
  );
};

export default Edit;
