import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import CECContext from "./CecContext";

const styles = {
  media: {
    width: "auto",
    height: 100,
    objectFit: "contain"
  },
  textBox: {
    minHeight: 75,
    maxHeight: 75
  },
  imageWrapper: {
    height: "100%",
    width: "100%",
    maxWidth: "450px",
  },
  paperWrapper: {
    width: 265,
    maxWidth: 265,
    transition: "transform 0.25s",
    "&:hover": {
      transform: "scale(1.05)"
    },
    height: 225,
    maxHeight: 230
  },
  paperFeitian: {
    width: 265,
    maxWidth: 265,
    height: 210,
    transition: "transform 0.25s",
    "&:hover": {
      transform: "scale(1.05)"
    }
  },
  link: {
    textDecoration: "none"
  },
  description: {
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#002F4D"
  },
  descFeitian: {
    marginTop: '15%',
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#002F4D"
  },
  img1: {

  },
  img2: {
    marginTop: '15%',
    marginBottom: '15%'
  }
};
const PropsBox = props => {
  const { classes } = props;
  return (
    <CECContext.Consumer>
      {value => (
        <div className={classes.paperWrapper}>
          <Link
            to={{
              pathname: props.link,
              state: { cec: value }
            }}
            className={classes.link}
          >
            <Paper elevation={1}>
              <img
                src={props.image}
                width={props.title==="Feitian Keys" ? 200 : (props.width || 65)}
                height={props.title==="Feitian Keys" ? 95 : (props.height || 85)}
                className={props.title==="Feitian Keys" ? classes.img2 : classes.img1}
                alt=""
              />
              <Typography
                variant="h6"
                component="h5"
                gutterBottom
                className={props.title==="Fietian Keys" ? classes.descFeitian : classes.description}
              >
                {props.title}
              </Typography>
            </Paper>
          </Link>
        </div>
      )}
    </CECContext.Consumer>
  );
};

export default withStyles(styles)(PropsBox);
