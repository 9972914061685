import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { ciscoAuthFeitian, setToken } from "./Oauth";

const AuthRecieverFeitian = () => {
  const [authorized, setAuthorized] = useState(false);

  const authorize = () => {
    const uri = window.location.href;
    ciscoAuthFeitian.token.getToken(uri).then(user => {
      if (user !== undefined) {
        setToken(user);
        setAuthorized(true);
      }
      return user;
    });
  };

  useEffect(() => {
    authorize();
  }, []);

  return authorized ? <Redirect to="/feitian" /> : <></>;
};

export default AuthRecieverFeitian;