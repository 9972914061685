import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Background from "../Common/Background";
import Title from "./Title";
import Choice from "./Choice";
import { ciscoAuth, getToken } from "../Oauth/Oauth";
import CECContext from "./CecContext";

const styles = {
  root: {
    textAlign: "center",
    paddingTop: 50
  }
};

const RegisterChoice = props => {
  const { classes } = props;
  const [auth, setAuth] = useState(false);
  const [cec, setCec] = useState("");

  const env = localStorage.getItem('environment');
  let authUrl = "https://cloudsso-test.cisco.com/idp/userinfo.openid";
  if(env==='prod'){
    authUrl = "https://cloudsso.cisco.com/idp/userinfo.openid";
  } else{
    console.log("not prod");
  }

  const getCec = () => {
    const config = {
      headers: { Authorization: 'Bearer '+getToken() }
    };
    return axios
    //.get("https://cloudsso.cisco.com/idp/userinfo.openid", config)
    //.get("https://cloudsso-test.cisco.com/idp/userinfo.openid", config)
    .get(authUrl, config)
    .then(response => {
      setCec(response.data.sub);
    })
    .catch(error => {
      if (error.response.statusText === "Unauthorized") {
        console.log("Refresh Trigerred");
        refreshToken();
      }
    });
  };

  const redirect = () => {
    if (getToken() === null) {
      window.location = ciscoAuth.token.getUri();
    } else {
      setAuth(true);
      getCec();
    }
  };

  const refreshToken = () => {
    window.location = ciscoAuth.token.getUri();
  };

  useEffect(() => {
    redirect();
  }, []);

  return auth ? (
    <CECContext.Provider value={cec}>
      <div>
        <Background />
        <div>
          <div className={classes.root}>
            <Title message="Which device would you like to register for OTP?" />
          </div>
          <Choice />
        </div>
      </div>
    </CECContext.Provider>
  ) : (
    <></>
  );
};

export default withStyles(styles)(RegisterChoice);
