// Local
const env = localStorage.getItem("environment");
let basePath = "";
// const basePath = "";
if (env === "local") {
  basePath = "//0.0.0.0:8080/";
}
if (env === "dev") {
  basePath = "//duo-token-api-dev-ext.cisco.com/";
}
if (env === "stage") {
  basePath = "//duo-token-api-stg-ext.cisco.com/";
}
if (env === "prod") {
  basePath = "//duo-token-api-ext.cisco.com/";
}

/** * For Disco as internal application (CAEAI)
if (env==='local'){
    basePath = "//0.0.0.0:8080/";
}
if(env==='dev'){
    basePath = "//duo-token-api-cisco.cisco.com/";
}
if(env==='stage'){
    basePath = "//duo-token-api-stg.cisco.com/";
}
if(env==='prod'){
    basePath = "//duo-token-api.cisco.com/";
}

*/

// Dev
// const basePath = "https://duo-token-api-cisco.cisco.com/";

// Stage
// const basePath = "https://duo-token-api-stg.cisco.com/";

// Prod
// const basePath = "https://duo-token-api.cisco.com/";

export default basePath;
