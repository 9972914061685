import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PaperBox from "./Box";
// import helpPDF from "../../assets/help.pdf";
import yubiKey from "../../assets/yubikey.svg";
import video from "../../assets/video.svg";
import openCase from "../../assets/open-case.svg";
import teams from "../../assets/teams.svg";
import optin from "../../assets/opt-in.svg";
import target from "../../assets/try-it.svg";
import file from "../../assets/ciscoEdit.svg";
import key from "../../assets/keys.JPG";
import sharepoint from "../../assets/sharepoint.svg";
import enroll from "../../assets/enroll.svg";
import survey from "../../assets/survey.svg";
import help from "../../assets/help.svg";
import passwordless from "../../assets/Passwordless.jpg";

const styles = {
  tile: {
    margin: "0px 16px",
    minWidth: "255px",
    maxWidth: "265px"
  }
};

const Tiles = props => {
  const { classes } = props;
  const env = localStorage.getItem("environment");

  let enrollLink = "https://myduo-test.cisco.com";
  //"https://sso-345c0691.sso.duosecurity.com/saml2/sp/DIHYEDY4QYRWJMW40SK0/sso";
  //"https://cloudsso-test.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso-test.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP&TargetResource=https://disco-test.cisco.com/done/";
  let tryLink = "https://cisco-test.login.duosecurity.com/";
  //"https://cloudsso-test.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso-test.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP";

  let pilotLink = "http://localhost:8087/DUOAPI/pilot";
  // let helpPDFLink = "https://disco-dev.cisco.com/DuoMultiFactorAuthentication.pdf";

  let helpPDFLink = "https://cloudsso.cisco.com/faqs/duo/duo.html";

  if (env === "dev") {
    pilotLink = "https://disco-dev.cisco.com/DUOAPI/pilot";
    // helpPDFLink = "https://disco-dev.cisco.com/DuoMultiFactorAuthentication.pdf";
  }

  if (env === "stage") {
    pilotLink = "https://disco-test.cisco.com/DUOAPI/pilot";
    // helpPDFLink = "https://disco-test.cisco.com/DuoMultiFactorAuthentication.pdf";
    helpPDFLink = "https://cloudsso.cisco.com/faqs/duo/duo.html";
  }

  if (env === "prod") {
    enrollLink = "https://myduo.cisco.com";
    //"https://sso-dbbfec7f.sso.duosecurity.com/saml2/sp/DIPBSMTWIMXUX4CESYFQ/sso";
    //"https://cloudsso.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP&TargetResource=https://disco.cisco.com/done/";
    tryLink = "https://cisco.login.duosecurity.com/";
    //"https://cloudsso.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP&TargetResource=https://sso.cisco.com/autho/apps/duo/index.html";
    pilotLink = "https://disco.cisco.com/DUOAPI/pilot";
    // helpPDFLink = "https://disco.cisco.com/DuoMultiFactorAuthentication.pdf";
    helpPDFLink = "https://cloudsso.cisco.com/faqs/duo/duo.html";
  }

  const content = [
    {
      // link: "https://cloudsso.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP&TargetResource=https://disco.cisco.com/done/",
      // link: "https://cloudsso-test.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso-test.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP&TargetResource=https://disco-test.cisco.com/done/",
      link: enrollLink,
      image: enroll,
      // title: "Enroll",
      width: 150,
      height: 150,
      description: "Brief description",
      tooltip: "Register with Duo MFA"
    },
    {
      link: enrollLink,
      // "/edit-info" /* place holder with image and arrow showin where they should click, then they click on it to go to same link as above */,
      image: file,
      width: 150,
      height: 150,
      // title: "Edit",
      description: "Edit profile",
      tooltip: "Manage Duo Profile"
    },
    {
      link: "/PasswordLess" /* place holder with image and arrow showin where they should click, then they click on it to go to same link as above */,
      image: passwordless,
      width: 150,
      height: 150,
      // title: "Try It",
      description: "Passwordless",
      tooltip: "Passwordless Login"
    },
    {
      link: "https://disco.cisco.com/teams",
      image: teams,
      width: 150,
      height: 150,
      // title: "Teams",
      description: "Webex Teams",
      tooltip: "Join Duo Support Teams Space"
    },
    {
      // link: "https://disco.cisco.com/DuoMultiFactorAuthentication.pdf",
      link: helpPDFLink,
      image: help,
      width: 150,
      height: 150,
      // title: "Need Help?",
      description: "Help",
      tooltip: "Duo Help"
    },
    {
      link: "https://disco.cisco.com/case",
      image: openCase,
      width: 150,
      height: 150,
      // title: "Open Case",
      description: "Open case",
      tooltip: "Open Support Case"
    },

    {
      link: "https://disco.cisco.com/orderyubikey",
      image: yubiKey,
      width: 135,
      height: 117,
      title: "Order Yubikey",
      description: "Order Yubikey",
      tooltip: "Order Yubikey"
    },
    {
      link: "/register-choice",
      image: key,
      width: 135,
      height: 119,
      title: "Register OTP Token",
      description: "Register digipass,Yubikey or Feitian Key",
      tooltip: "Register Hardware Token Devices"
    },
    {
      link: "https://disco.cisco.com/sharepoint",
      image: sharepoint,
      width: 180,
      height: 150,
      // title: "Employee Community",
      description: "Duo MFA Sharepoint",
      tooltip: "Duo MFA Sharepoint"
    }
    // {
    //     link: "https://apps.na.collabserv.com/forums/html/forum?id=104e4851-2788-42a9-9b8a-39615208c1b5&ps=25",
    //     image: forum,
    //     width: 250,
    //     height: 120,
    //     // title: "Forums",
    //     description: ""
    // }
    // {
    //     link: "https://disco.cisco.com/lazyegg",
    //     image: lazyEgg,
    //     width: 125,
    //     title: "Lazy Egg Pilot (Stage)",
    //     description: ""
    // }
    //{
    //link: tryLink,
    //image: target,
    //width: 150,
    //height: 150,
    //description: "Try Duo MFA",
    //tooltip: "Try Duo MFA"
    //}

  ];
  return (
    <Grid container spacing={16} justify="center">
      {content.map((tile, index) => (
        <Grid
          item
          xs={3}
          className={classes.tile}
          key={`grid-item${index * 1.01}`}
        >
          <PaperBox
            title={tile.title}
            link={tile.link}
            image={tile.image}
            width={tile.width}
            height={tile.height}
            tooltip={tile.tooltip}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default withStyles(styles)(Tiles);
