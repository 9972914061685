const ClientOauth = require("client-oauth2");

let host = window.location.host;
const authUrl = (host === "disco.cisco.com") ? "https://cloudsso.cisco.com/as/authorization.oauth2" : "https://cloudsso-test.cisco.com/as/authorization.oauth2";

export const ciscoAuth = new ClientOauth({
  clientId: "DuoToken",
  authorizationUri: authUrl, 
  redirectUri: (host.indexOf("localhost") > -1 ? "http://" : "https://") + host + "/auth",
  scopes: ["openid email profile address phone"]
});
export const ciscoAuthYubikey = new ClientOauth({
  clientId: "DuoToken",
  authorizationUri: authUrl,
  redirectUri: (host.indexOf("localhost") > -1 ? "http://" : "https://") + host + "/auth-yubikey",
  scopes: ["openid email profile address phone"]
});

export const ciscoAuthDigiPass = new ClientOauth({
  clientId: "DuoToken",
  authorizationUri: authUrl,
  redirectUri: (host.indexOf("localhost") > -1 ? "http://" : "https://") + host + "/auth-digipass",
  scopes: ["openid email profile address phone"]
});

export const ciscoAuthFeitian = new ClientOauth({
  clientId: "DuoToken",
  authorizationUri: authUrl,
  redirectUri: (host.indexOf("localhost") > -1 ? "http://" : "https://") + host + "/auth-feitian",
  scopes: ["openid email profile address phone"]
});

export function getToken() {
  const token = localStorage.getItem("oauth");
  return token;
}

export function setToken(user) {
  return localStorage.setItem("oauth", user.accessToken);
}
