import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import InputMask from "react-input-mask";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getToken } from "../../Oauth/Oauth";
import duoKeys from "../../../assets/duo-keys.svg";
import basePath from "../../../utils";

const styles = {
  root: {
    margin: "0 auto",
    maxWidth: "800px"
  },
  textContent: {
    marginBottom: "10px",
    fontSize: "large"
  },
  successMsg: {
    marginBottom: "10px",
    fontSize: "large",
    color: "green"
  },
  errMsg: {
    marginBottom: "10px",
    fontSize: "large",
    color: "red"
  },
  button: {
    marginRight: 10
  },
  displayContainer: {
    display: "flex",
    flexDirection: "column"
  },
  contentContainer: {
    minWidth: "200px",
    paddingTop: "15px",
    paddingRight: "10px",
    paddingLeft: "30px",
    fontSize: "large"
  },
  duoImgContainer: {
    width: "100%",
    minWidth: "150px",
    maxWidth: "450px",
    height: "100%",
    marginLeft: 30
  },
  registerButton: {
    paddingTop: "10px"
  },
  backButton: {
    paddingTop: 50,
    margin: "0 auto"
  },
  inputWrapper: {
    display: "flex"
  },
  icon: {
    marginRight: 5,
    height: 20,
    width: 20
  },
  responseContainer: {
    display: "flex"
  },
  responseImage: {
    width: 50,
    height: 50,
    paddingLeft: 10
  }
};

const verifyInput = (input, regexp) => {
  const re = new RegExp(regexp);
  return !re.test(input);
};

const Input = props => {
  return (
    <InputMask
      mask={props.mask}
      value={props.value}
      onChange={props.onChange}
      maskChar={props.maskChar}
    >
      {() => (
        <TextField
          id="outlined-with-placeholder"
          label={props.label}
          margin="normal"
          variant="outlined"
          error={props.verifyFunc}
        />
      )}
    </InputMask>
  );
};

const Content = props => {
  const { classes } = props;
  const [serialNum, setSerialNum] = React.useState("");
  const [apiResponse, setApiResponse] = React.useState("");
  const [loading, setLoading] = React.useState();
  const [cecYes, setCecYes] = React.useState(); // whether user hit y/n for correct cec username
  const [cecNo, setCecNo] = React.useState(); // whether user hit y/n for correct cec username

  const handleApiResponse = response => {
    const SuccessResponseMessage = () => {
      return (
        <Typography className={ classes.successMsg }>
          {response.message}
        </Typography>
      );
    };
    const ErrorResponseMessage = () => {
      return (
        <Typography className={ classes.errMsg }>
          {response.message}
        </Typography>
      );
    };
    const successful = ["AR", "FRU"];
    if (successful.includes(response.status)) {
      return (
        <>
          <Typography variant="h5">
            Congratulations, you&apos;re all set!
          </Typography>
          <SuccessResponseMessage />
        </>
      );
    }
    if (response.status === "UA") {
      return (
        <>
          <Typography variant="h5"   className={ classes.errMsg }>
            Unsuccessful Digipass Registration
          </Typography>
          <ErrorResponseMessage />
        </>
      );
    }
    if (response.status === "AUR") {
      return (
        <>
          <Typography variant="h5"   className={ classes.errMsg }>
            Another user is already registered to this digipass
          </Typography>
          <ErrorResponseMessage />
        </>
      );
    }
    if (response.status === "ISN") {
      return (
        <>
          <Typography variant="h5"  className={ classes.errMsg }>Digipass Not Found</Typography>
          <ErrorResponseMessage />
        </>
      );
    }
    if (response.status === "ERR") {
      return (
        <>
          <Typography variant="h5"  className={ classes.errMsg }>Something went Wrong</Typography>
          <ErrorResponseMessage />
        </>
      );
    }
    return <ErrorResponseMessage />;
  };

  const handleCecYes = () => {
    if (cecNo) {
      setCecNo(false);
    }
    setCecYes(true);
  };

  const handleCecNo = () => {
    if (cecYes) {
      setCecYes(false);
    }
    setCecNo(true);
  };

  const progressBar = isLoading => {
    return isLoading ? (
      <div>
        <LinearProgress />
      </div>
    ) : (
      <></>
    );
  };

  const sendRequest = text => {
    setLoading(true);
    setApiResponse("");
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` }
    };
    axios
      .post(
        `${basePath}digipass`,
        {
          token: getToken(),
          serial_num: text
        },
        config
      )
      .then(response => {
        setLoading(false);
        setApiResponse(response.data);
      });
  };

  return (
    <div className={classes.displayContainer}>
      <Paper className={classes.root}>
        <div className={classes.contentContainer}>
          <Typography variant="h5" gutterBottom>
            Enter Digipass Serial Number
          </Typography>
          <Typography className={classes.textContent} gutterBottom>
            Please confirm that this is your CEC ID: <b>{props.cec}</b>
          </Typography>
          <Button
            variant={cecYes ? "contained" : "outlined"}
            size="small"
            color="primary"
            className={classes.button}
            onClick={handleCecYes}
          >
            Yes
          </Button>
          <Button
            variant={cecNo ? "contained" : "outlined"}
            size="small"
            onClick={handleCecNo}
          >
            No
          </Button>
          <br />
          <br />
          <Typography className={classes.textContent}>
            Please enter the serial number on the back of your Duo digipass.
          </Typography>
          <div className={classes.inputWrapper}>
            <Input
              mask="9999999999"
              value={serialNum}
              onChange={({ target: { value } }) => setSerialNum(value)}
              maskChar=""
              label="FOB Serial Number"
              verifyFunc={verifyInput(serialNum, /\d{10}/)}
            />
            <img
              src={duoKeys}
              alt="duo fob"
              className={classes.duoImgContainer}
            />
          </div>
          <div className={classes.registerButton}>
            <Button
              onClick={() => sendRequest(serialNum)}
              disabled={!cecYes || verifyInput(serialNum, /\d{10}/g)}
              variant="contained"
              color="primary"
            >
              Register
            </Button>
          </div>
          <br />
          {progressBar(loading)}
          {handleApiResponse(apiResponse)}
        </div>
      </Paper>
      <div className={classes.backButton}>
        <Button variant="contained" color="secondary" href="/">
          <DashboardIcon className={classes.icon} />
          back to dashboard
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(Content);
