import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Background from "../Common/Background";
import Title from "./Title";
import Tiles from "./Tiles";

const styles = {
  content: {
    position: "absolute",
    left: 0,
    right: 0,
    margin: "auto",
    paddingTop: 50,
    paddingBottom: 50,
    textAlign: "center",
    zIndex: 10,
    maxWidth: 1000,
    display: "flex",
    flexDirection: "column"
  },
  titleGap: {
    height: 15
  }
};

const Dashboard = props => {
  const { classes } = props;
  return (
    <>
      <div className={classes.content}>
        <Title />
        <span className={classes.titleGap} />
        <Tiles />
      </div>
      <Background />
    </>
  );
};

export default withStyles(styles)(Dashboard);
