import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { ciscoAuthYubikey, setToken } from "./Oauth";

const AuthRecieverYubikey = () => {
  const [authorized, setAuthorized] = useState(false);

  const authorize = () => {
    const uri = window.location.href;
    ciscoAuthYubikey.token.getToken(uri).then(user => {
      if (user !== undefined) {
        setToken(user);
        setAuthorized(true);
      }
      return user;
    });
  };

  useEffect(() => {
    authorize();
  }, []);

  return authorized ? <Redirect to="/yubikey" /> : <></>;
};

export default AuthRecieverYubikey;