import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Background from "../../Common/Background";
import Content from "./Content";
import Title from "../Title";
import { ciscoAuthYubikey, getToken } from "../../Oauth/Oauth";

const styles = {
  root: {
    textAlign: "center",
    paddingTop: 50
  },
  rootNew: {
    textAlign: "center",
    paddingTop: 25,
    paddingBottom: 0
  }
};

const Yubikey = props => {
  const { classes } = props;
  const [auth, setAuth] = useState(false);
  const [cec, setCec] = useState("");

  const env = localStorage.getItem('environment');
  let authUrl = "https://cloudsso-test.cisco.com/idp/userinfo.openid";
  if(env==='prod'){
    authUrl = "https://cloudsso.cisco.com/idp/userinfo.openid";
  }
  
  const getCec = () => {
    const config = {
      headers: { Authorization: 'Bearer '+getToken() }
    };
    return axios
      //.get("https://cloudsso.cisco.com/idp/userinfo.openid", config)
      //.get("https://cloudsso-test.cisco.com/idp/userinfo.openid", config)
      .get(authUrl, config)
      .then(response => {
        setCec(response.data.sub);
      })
      .catch(error => {
        if (error.response.statusText === "Unauthorized") {
          console.log("Refresh Trigerred");
          refreshToken();
        }
      });
  };

  const redirect = () => {
    if (getToken() === null) {
      window.location = ciscoAuthYubikey.token.getUri();
    } else {
      setAuth(true);
      getCec();
    }
  };

  const refreshToken = () => {
    window.location = ciscoAuthYubikey.token.getUri();
  };

  useEffect(() => {
    redirect();
  }, []);

  return auth ? (
    <div>
      <Background />
      <div>
        <div className={classes.rootNew}>
          <Title message="Register a new Yubikey for OTP mode" />
        </div>
        <Content cec={cec} />
      </div>
    </div>
  ) : (
      <></>
    );

  // const { cec } = props.location.state;
  // return (
  // <div>
  //   <Background />
  //   <div>
  //     <div className={classes.root}>
  //       <Title message="Register a new Yubikey" />
  //     </div>
  //     <Content cec={cec} />
  //   </div>
  // </div>
  // );
};

export default withStyles(styles)(Yubikey);